import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "experimental-resources-are-components-patterns-and-tools-currently-in-the-works-carbon-is-a-collaborative-and-distributed-effort-if-you-like-what-you-see-the-carbon-team-wants-your-help"
    }}>{`Experimental resources are components, patterns, and tools currently in the works. Carbon is a collaborative and distributed effort. If you like what you see, the Carbon team wants your help.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">How this works</AnchorLink>
      <AnchorLink mdxType="AnchorLink">What you need to know</AnchorLink>
      <AnchorLink mdxType="AnchorLink">We want your help</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "how-this-works"
    }}>{`How this works`}</h2>
    <p>{`Carbon is always getting better. This happens through continual feature and usability upgrades to our elements and core components. It also happens through exploration and experimentation. The Carbon team will surface this exploration here.`}</p>
    <p>{`Expect these components, patterns, and tools to change over time. If you choose to use experimental resources, you can help move them closer to production by contributing feedback, code, and design.`}</p>
    <p>{`Some of these resources will become core parts of the Carbon Design System.`}</p>
    <h2 {...{
      "id": "what-you-need-to-know"
    }}>{`What you need to know`}</h2>
    <p>{`Experimental components, designs, and other resources are presented for testing and feedback. They are not intended for production use.`}</p>
    <p>{`We know major releases can mean major work for designers and developers. We use experimental projects to surface future resources and get them into the hands of teams who will use them.`}</p>
    <p>{`They may have some non-functional pieces, or be lacking in documentation. `}<strong parentName="p">{`There is no guarantee of support`}</strong>{`.`}</p>
    <h2 {...{
      "id": "we-want-your-help"
    }}>{`We want your help`}</h2>
    <p>{`The Carbon team is continually updating components and introducing new design and development guidelines.`}</p>
    <p>{`We want to provide early-access to our work. Designers and developers can test, work with, and provide feedback for experimental projects.`}</p>
    <h3 {...{
      "id": "how-can-i-help"
    }}>{`How can I help?`}</h3>
    <p>{`Your feedback is critical for every aspect of Carbon Design System development process. Input and contributions help experimental components improve and “graduate” to production release.`}</p>
    <p>{`Please open a GitHub issue from the footer of any page on the Carbon website to let the team know what you think. If you see a problem, fix it! Help us build the web’s best design system.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      